@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

@media only screen and (min-width: 2001px) {
    :root {
        --blogr05: 1rem;
        --blogr08: 1.6rem;
        --blogr1: 2rem;
        --blogr101: 2.2rem;
        --blogr102: 2.4rem;
        --blogr103: 2.6rem;
        --blogr104: 2.8rem;
        --blogr105: 3rem;
        --blogr106: 3.2rem;
        --blogr2: 4rem;
        --blogr3: 6rem;
        --blogr4: 8rem;
        --blogr5: 10rem;
    }
}
@media only screen and (max-width: 2000px) {
    :root {
        --blogr05: 0.75rem;
        --blogr08: 1.2rem;
        --blogr1: 1.5rem;
        --blogr101: 1.6rem;
        --blogr102: 1.7rem;
        --blogr103: 1.9rem;
        --blogr104: 2rem;
        --blogr105: 2.2rem;
        --blogr106: 2.3rem;
        --blogr2: 3rem;
        --blogr3: 4.5rem;
        --blogr4: 6rem;
        --blogr5: 7.5rem;
    }
}
@media only screen and (max-width: 1670px) {
    :root {
        --blogr05: 0.625rem;
        --blogr08: 1rem;
        --blogr1: 1.25rem;
        --blogr101: 1.3rem;
        --blogr102: 1.5rem;
        --blogr103: 1.6rem;
        --blogr104: 1.7rem;
        --blogr105: 1.8rem;
        --blogr106: 1.9rem;
        --blogr2: 2.5rem;
        --blogr3: 3.75rem;
        --blogr4: 5rem;
        --blogr5: 6.25rem;
    }
}
@media only screen and (max-width: 1440px) {
    :root {
        --blogr05: 0.5rem;
        --blogr08: 0.8rem;
        --blogr1: 1rem;
        --blogr101: 1.1rem;
        --blogr102: 1.2rem;
        --blogr103: 1.3rem;
        --blogr104: 1.4rem;
        --blogr105: 1.5rem;
        --blogr106: 1.6rem;
        --blogr2: 2rem;
        --blogr3: 3rem;
        --blogr4: 4rem;
        --blogr5: 5rem;
    }
}
@media only screen and (max-width: 1024px) {
    :root {
        --blogr05: 0.35rem;
        --blogr08: 0.56rem;
        --blogr1: 0.7rem;
        --blogr101: 0.8rem;
        --blogr102: 0.9rem;
        --blogr103: 1rem;
        --blogr104: 1.2rem;
        --blogr105: 1.3rem;
        --blogr106: 1.4rem;
        --blogr2: 1.4rem;
        --blogr3: 2.1rem;
        --blogr4: 2.8rem;
        --blogr5: 3.5rem;
    }
}
@media only screen and (max-width: 768px) {
    :root {
        --blogr05: 0.25rem;
        --blogr08: 0.4rem;
        --blogr1: 0.5rem;
        --blogr101: 0.55rem;
        --blogr102: 0.6rem;
        --blogr103: 0.7rem;
        --blogr104: 0.7rem;
        --blogr105: 0.75rem;
        --blogr106: 0.8rem;
        --blogr2: 1rem;
        --blogr3: 1.5rem;
        --blogr4: 2rem;
        --blogr5: 2.5rem;
    }
    body .blogcircle {
        width: calc(var(--blogr4) * 1.8);
        height: calc(var(--blogr4) * 1.8);
    }
}
@media only screen and (max-width: 530px) {
    :root {
        --blogr05: 0.25rem;
        --blogr08: 0.4rem;
        --blogr1: 0.5rem;
        --blogr101: 0.72rem;
        --blogr102: 0.9rem;
        --blogr103: 1rem;
        --blogr104: 1.2rem;
        --blogr105: 1.3rem;
        --blogr106: 1.4rem;
        --blogr2: 1.2rem;
        --blogr3: 1.5rem;
        --blogr4: 2rem;
        --blogr5: 2.5rem;
    }
    body .blogevent {
        justify-content: center;
    }
    body .blogcircle {
        margin-right: auto;
        margin-left: 10%;
        margin-bottom: var(--blogr1);
    }
    body .blogcirclecont {
        display: block;
        width: 100%;
    }
    body .blogslider {
        width: 85%;
        margin-left: auto;
    }
}
@media only screen and (max-width: 400px) {
    :root {
        --blogr05: 0.18rem;
        --blogr08: 0.3rem;
        --blogr1: 0.375rem;
        --blogr101: 0.4rem;
        --blogr102: 0.45rem;
        --blogr103: 0.48rem;
        --blogr104: 0.5rem;
        --blogr105: 0.54rem;
        --blogr106: 0.58rem;
        --blogr2: 0.7rem;
        --blogr3: 1rem;
        --blogr4: 1.4rem;
        --blogr5: 1.7rem;
    }
}

.blogevent {
    display: flex;
    width: 90%;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: auto;
    padding: 5% 0;
    position: relative;
}
.blogcirclecont {
    display: contents;
}
.blogcircle {
    width: calc(var(--blogr4) * 2);
    height: calc(var(--blogr4) * 2);
    display: block;
    border: 2px solid #00395f;
    border-radius: 100%;
    position: relative;
}
.blogvert {
    position: absolute;
    top: 50%;
    border: 1.5px solid #284d97;
    display: inline;
}
.bloghor {
    position: absolute;
    right: 100%;
    top: 50%;
    border: 1.5px solid #284d97;
    display: inline;
}

.blogcircle p {
    color: #00395f;
    font-family: "Inter", sans-serif;
    font-size: var(--blogr101);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    margin: 0;
}
.blogslider {
    width: 65%;
}
.blogintroanim {
    height: calc(var(--blogr4) * 8);
    position: relative;
    overflow: hidden;
}
.blogintroanim > div {
    position: absolute;
    width: 100%;
    height: 100%;
    transition-property: all;
    transition-delay: 0s;
    transition-duration: 1s;
    transition-timing-function: ease-in;
    animation-name: go;
    animation-delay: 0s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
}
.blogintroanim > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@keyframes come {
    from {
        right: -100%;
    }
    to {
        right: 0;
    }
}
@keyframes go {
    from {
        left: 0;
    }
    to {
        left: -100%;
    }
}
.blogslider h1 {
    color: #00395f;
    font-family: "Raleway", sans-serif;
    font-size: var(--blogr2);
}
.blogmorelink {
    display: block;
    width: fit-content;
    padding: var(--blogr08);
    color: white;
    background-color: #00395f;
    text-decoration: none;
    font-size: var(--blogr2);
    border: 1px solid transparent;
    border-radius: 8%;
}
.blogmorelink:hover {
    background-color: white;
    color: #00395f;
    border: 1px solid #00395f;
}
