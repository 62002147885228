@media only screen and (min-width: 2001px) {
    :root {
        --projectsr05: 1rem;
        --projectsr08: 1.6rem;
        --projectsr1: 2rem;
        --projectsr101: 2.2rem;
        --projectsr102: 2.4rem;
        --projectsr103: 2.6rem;
        --projectsr104: 2.8rem;
        --projectsr105: 3rem;
        --projectsr106: 3.2rem;
        --projectsr2: 4rem;
        --projectsr3: 6rem;
        --projectsr4: 8rem;
        --projectsr5: 10rem;
    }
}
@media only screen and (max-width: 2000px) {
    :root {
        --projectsr05: 0.75rem;
        --projectsr08: 1.2rem;
        --projectsr1: 1.5rem;
        --projectsr101: 1.6rem;
        --projectsr102: 1.7rem;
        --projectsr103: 1.9rem;
        --projectsr104: 2rem;
        --projectsr105: 2.2rem;
        --projectsr106: 2.3rem;
        --projectsr2: 3rem;
        --projectsr3: 4.5rem;
        --projectsr4: 6rem;
        --projectsr5: 7.5rem;
    }
}
@media only screen and (max-width: 1670px) {
    :root {
        --projectsr05: 0.625rem;
        --projectsr08: 1rem;
        --projectsr1: 1.25rem;
        --projectsr101: 1.3rem;
        --projectsr102: 1.5rem;
        --projectsr103: 1.6rem;
        --projectsr104: 1.7rem;
        --projectsr105: 1.8rem;
        --projectsr106: 1.9rem;
        --projectsr2: 2.5rem;
        --projectsr3: 3.75rem;
        --projectsr4: 5rem;
        --projectsr5: 6.25rem;
    }
}
@media only screen and (max-width: 1440px) {
    :root {
        --projectsr05: 0.5rem;
        --projectsr08: 0.8rem;
        --projectsr1: 1rem;
        --projectsr101: 1.1rem;
        --projectsr102: 1.2rem;
        --projectsr103: 1.3rem;
        --projectsr104: 1.4rem;
        --projectsr105: 1.5rem;
        --projectsr106: 1.6rem;
        --projectsr2: 2rem;
        --projectsr3: 3rem;
        --projectsr4: 4rem;
        --projectsr5: 5rem;
    }
}
@media only screen and (max-width: 1024px) {
    :root {
        --projectsr05: 0.35rem;
        --projectsr08: 0.56rem;
        --projectsr1: 0.7rem;
        --projectsr101: 0.8rem;
        --projectsr102: 0.9rem;
        --projectsr103: 1rem;
        --projectsr104: 1.2rem;
        --projectsr105: 1.3rem;
        --projectsr106: 1.4rem;
        --projectsr2: 1.4rem;
        --projectsr3: 2.1rem;
        --projectsr4: 2.8rem;
        --projectsr5: 3.5rem;
    }
}
@media only screen and (max-width: 768px) {
    :root {
        --projectsr05: 0.25rem;
        --projectsr08: 0.4rem;
        --projectsr1: 0.5rem;
        --projectsr101: 0.55rem;
        --projectsr102: 0.6rem;
        --projectsr103: 0.7rem;
        --projectsr104: 0.7rem;
        --projectsr105: 0.75rem;
        --projectsr106: 0.8rem;
        --projectsr2: 1rem;
        --projectsr3: 1.5rem;
        --projectsr4: 2rem;
        --projectsr5: 2.5rem;
    }
    body .projctscenteredsearch {
        width: 90vw;
    }
}
@media only screen and (max-width: 580px) {
    :root {
        --projectsr05: 0.25rem;
        --projectsr08: 0.4rem;
        --projectsr1: 0.5rem;
        --projectsr101: 0.55rem;
        --projectsr102: 0.6rem;
        --projectsr103: 0.7rem;
        --projectsr104: 0.7rem;
        --projectsr105: 0.75rem;
        --projectsr106: 0.8rem;
        --projectsr2: 1rem;
        --projectsr3: 1.5rem;
        --projectsr4: 2rem;
        --projectsr5: 2.5rem;
    }
    body .projctscenteredsearch {
        width: 90vw;
        justify-content: center;
        flex-direction: row-reverse;
    }
    body .projectssearchaction {
        width: 90%;
        height: 23vh;
        display: block;
        margin: auto;
    }
    body .projectssearchresults {
        width: 90%;
        height: 55vh;
        max-height: 56vh;
        display: block;
        margin: auto;
    }
}
@media only screen and (max-width: 530px) {
    :root {
        --projectsr05: 0.25rem;
        --projectsr08: 0.4rem;
        --projectsr1: 0.5rem;
        --projectsr101: 0.8rem;
        --projectsr102: 0.9rem;
        --projectsr103: 1rem;
        --projectsr104: 1.2rem;
        --projectsr105: 1.3rem;
        --projectsr106: 1.4rem;
        --projectsr2: 1.4rem;
        --projectsr3: 1.5rem;
        --projectsr4: 2rem;
        --projectsr5: 2.5rem;
    }
    body .projectscategorycont div {
        width: 47%;
    }
}
@media only screen and (max-width: 400px) {
    :root {
        --projectsr05: 0.18rem;
        --projectsr08: 0.3rem;
        --projectsr1: 0.375rem;
        --projectsr101: 0.4rem;
        --projectsr102: 0.45rem;
        --projectsr103: 0.48rem;
        --projectsr104: 1.2rem;
        --projectsr105: 0.54rem;
        --projectsr106: 0.58rem;
        --projectsr2: 0.7rem;
        --projectsr3: 1rem;
        --projectsr4: 1.4rem;
        --projectsr5: 1.7rem;
    }
    body .projectssearchaction {
        font-size: var(--projectsr2);
    }
    body .projectssearchaction select {
        font-size: var(--projectsr2);
    }
    body .projectssearchinput {
        font-size: var(--projectsr2);
    }
    body .projectssearchresults h1 {
        font-size: var(--projectsr2);
    }
    body .projectssearchresults h2 {
        font-size: var(--projectsr2);
    }
    body .projectssearchresults h3 {
        font-size: var(--projectsr2);
    }
    body .projectssearchresults h4 {
        font-size: var(--projectsr2);
    }
    body .projectscategorycont div {
        width: 100%;
    }
}
.projectssearchstartcont {
    display: block;
    width: 100%;
    margin: auto;
}
.projectssearchstart {
    all: unset;
    display: block;
    background-color: #012060;
    color: white;
    font-size: var(--projectsr103);
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
        "Lucida Sans", Arial, sans-serif;
    border-radius: 3%;
    padding: var(--projectsr08);
    margin: var(--projectsr08);
    margin-left: auto;
    margin-right: 7.3%;
    cursor: pointer;
}
.projectssearchstart:hover {
    background-color: blue;
}
.projectssearchwindow {
    display: none;
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100svw;
    height: 100lvh;
    background-color: rgb(1, 33, 96, 0.5);
}
.projectssearchclosebutton {
    position: fixed;
    top: 3%;
    right: 3%;
    width: 5svw;
    height: 5svw;
    filter: invert(1);
    cursor: pointer;
}
.projctscenteredsearch {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70svw;
    height: 80svh;
    border: 2px solid black;
    border-radius: 2%;
    background-color: whitesmoke;
    padding: var(--projectsr1);
}
.projectssearchaction {
    width: 40%;
    font-size: var(--projectsr102);
    font-family: "Raleway", sans-serif;
    color: #012060;
    font-weight: bold;
}
.projectssearchaction p {
    display: inline-block;
    margin-left: var(--projectsr05);
}
.projectssearchaction span p {
    display: inline-block;
    margin-left: 0;
}
.projectssearchaction span {
    display: inline-block;
    margin-left: var(--projectsr05);
}
.projectssearchaction select {
    display: inline-block;
    margin-left: var(--projectsr05);
    font-size: var(--projectsr102);
    font-family: "Raleway", sans-serif;
    color: #012060;
}
.projectssearchinput {
    display: block;
    width: 80%;
    margin: auto;
    margin-top: var(--projectsr08);
    font-size: var(--projectsr103);
    font-family: "Raleway", sans-serif;
    color: #012060;
}
.projectssearchresults {
    width: 50%;
    height: 78svh;
    overflow: scroll;
    position: relative;
}
.projectssearchresults h4 {
    margin:  0 auto;
    font-size: var(--projectsr104);
    font-weight: bold;
    text-decoration: underline;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    color: #012060;
    position: sticky;
    top: -1%;
    background-color: whitesmoke;
    z-index: 300;
    padding-bottom: var(--projectsr05);
    padding-top: var(--projectsr08);
}
.projectssearchresults h1 {
    font-size: var(--projectsr104);
    text-decoration: underline;
    font-family: Arial, Helvetica, sans-serif;
    color: #012060;
}
.projectssearchresults h2 {
    font-size: var(--projectsr103);
    padding: var(--projectsr05);
    color: white;
    text-transform: capitalize;
    z-index: 26;
    font-weight: bold;
    background-color: rgb(0, 0, 0, 0.5);
    margin-top: 0;
}
.projectssearchresults h3 {
    display: inline-block;
    font-size: var(--projectsr103);
    padding: var(--projectsr05);
    color: white;
    z-index: 26;
    font-weight: bold;
    background-color: #012060;
    position: absolute;
    bottom: 0;
    left: 0;
}
.projectssearchresults a {
    display: block;
    margin: auto;
    margin-right: auto;
    margin-bottom: var(--projectsr05);
    width: 90%;
    background-color: rgb(0, 0, 255, 0.1);
    border: 1px inset black;
    border-radius: 2%;
    font-family: Arial, Helvetica, sans-serif;
    position: relative;
    height: 33svh;
}
.projectssearchlinkshade {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.25);
    z-index: 1;
}
/*////////////////////////////
//////////////////////////////////
////////////////////////*/
.projectscategorycont {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: auto;
}
.projectscategorycont div {
    width: 30%;
    margin: var(--projectsr08) 0;
    border-radius: 4%;
}
.projectscategorycont div img {
    width: 100%;
    display: block;
    margin: 0 auto;
    border-top-left-radius: 4%;
    border-top-right-radius: 4%;
}
.projectscategorycont div a {
    border-radius: 4%;
    text-decoration: none;
}

.projectscategorycont div a p {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: var(--projectsr104);
    font-family: "Raleway", sans-serif;
    color: white;
    background-color: #012060;
    padding: var(--projectsr08) 0;
}
