@media only screen and (min-width: 2001px) {
    :root {
        --project1r05: 1rem;
        --project1r08: 1.6rem;
        --project1r1: 2rem;
        --project1r101: 2.2rem;
        --project1r102: 2.4rem;
        --project1r103: 2.6rem;
        --project1r104: 2.8rem;
        --project1r105: 3rem;
        --project1r106: 3.2rem;
        --project1r2: 4rem;
        --project1r3: 6rem;
        --project1r4: 8rem;
        --project1r5: 10rem;
    }
}
@media only screen and (max-width: 2000px) {
    :root {
        --project1r05: 0.75rem;
        --project1r08: 1.2rem;
        --project1r1: 1.5rem;
        --project1r101: 1.6rem;
        --project1r102: 1.7rem;
        --project1r103: 1.9rem;
        --project1r104: 2rem;
        --project1r105: 2.2rem;
        --project1r106: 2.3rem;
        --project1r2: 3rem;
        --project1r3: 4.5rem;
        --project1r4: 6rem;
        --project1r5: 7.5rem;
    }
}
@media only screen and (max-width: 1670px) {
    :root {
        --project1r05: 0.625rem;
        --project1r08: 1rem;
        --project1r1: 1.25rem;
        --project1r101: 1.3rem;
        --project1r102: 1.5rem;
        --project1r103: 1.6rem;
        --project1r104: 1.7rem;
        --project1r105: 1.8rem;
        --project1r106: 1.9rem;
        --project1r2: 2.5rem;
        --project1r3: 3.75rem;
        --project1r4: 5rem;
        --project1r5: 6.25rem;
    }
}
@media only screen and (max-width: 1440px) {
    :root {
        --project1r05: 0.5rem;
        --project1r08: 0.8rem;
        --project1r1: 1rem;
        --project1r101: 1.1rem;
        --project1r102: 1.2rem;
        --project1r103: 1.3rem;
        --project1r104: 1.4rem;
        --project1r105: 1.5rem;
        --project1r106: 1.6rem;
        --project1r2: 2rem;
        --project1r3: 3rem;
        --project1r4: 4rem;
        --project1r5: 5rem;
    }
}
@media only screen and (max-width: 1024px) {
    :root {
        --project1r05: 0.35rem;
        --project1r08: 0.56rem;
        --project1r1: 0.7rem;
        --project1r101: 0.8rem;
        --project1r102: 0.9rem;
        --project1r103: 1rem;
        --project1r104: 1.2rem;
        --project1r105: 1.3rem;
        --project1r106: 1.4rem;
        --project1r2: 1.4rem;
        --project1r3: 2.1rem;
        --project1r4: 2.8rem;
        --project1r5: 3.5rem;
    }
}
@media only screen and (max-width: 768px) {
    :root {
        --project1r05: 0.25rem;
        --project1r08: 0.4rem;
        --project1r1: 0.5rem;
        --project1r101: 0.55rem;
        --project1r102: 0.6rem;
        --project1r103: 0.7rem;
        --project1r104: 0.7rem;
        --project1r105: 0.75rem;
        --project1r106: 0.8rem;
        --project1r2: 1rem;
        --project1r3: 1.5rem;
        --project1r4: 2rem;
        --project1r5: 2.5rem;
    }
}
@media only screen and (max-width: 530px) {
    :root {
        --project1r05: 0.25rem;
        --project1r08: 0.4rem;
        --project1r1: 0.5rem;
        --project1r101: 0.8rem;
        --project1r102: 0.9rem;
        --project1r103: 1rem;
        --project1r104: 1.2rem;
        --project1r105: 1.3rem;
        --project1r106: 1.4rem;
        --project1r2: 1.4rem;
        --project1r3: 1.5rem;
        --project1r4: 2rem;
        --project1r5: 2.5rem;
    }
    body .project1project {
    padding: var(--project1r05) 0;
    border-radius: 2%;
}
    body .project1imgcont {
        width: 100%;
    }
    body .project1mainimg {
        width: 60%;
        display: block;
        margin: auto;
    }
    body .project1datacont h2 {
    text-align: center;
    display: block;
    margin: auto;
}
    body .project1datacont  {
        width: 100%;
    }
}
@media only screen and (max-width: 400px) {
    :root {
        --project1r05: 0.25rem;
        --project1r08: 0.4rem;
        --project1r1: 0.5rem;
        --project1r101: 0.8rem;
        --project1r102: 0.9rem;
        --project1r103: 1rem;
        --project1r104: 1.2rem;
        --project1r105: 1.3rem;
        --project1r106: 1.4rem;
        --project1r2: 1.4rem;
        --project1r3: 1.5rem;
        --project1r4: 2rem;
        --project1r5: 2.5rem;
    }
}

.project1container h1 {
    font-size: var(--project1r3);
    color: #012060;
    font-weight: bold;
    font-family: "ubuntu", sans-serif;
    display: block;
    margin: auto;
    z-index: 30;
    width: 80%;
    text-align: center;
}

.project1container {
    display: block;
    margin: auto;
    width: 90%;
}
.project1project {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    margin: var(--project1r2) auto;
    border: solid #012060;
    border-top-right-radius: 2%;
    border-top-left-radius: 2%;
    padding: var(--project1r08);
}

.project1imgcont {
    width: 40%;
}
.project1mainimg {
    width: 100%;
}
.project1datacont {
    width: 50%;
}
.project1datacont h2 {
    font-size: var(--project1r2);
    color: #00395f;
    font-weight: bold;
    font-family: "Raleway", sans-serif;
    display: inline-block;
    margin: auto;
    z-index: 30;
    text-align: center;
}

.project1datacont ul {
    list-style-type: none;
    display: block;
    margin-right: auto;
    width: fit-content;
}
.project1datacont li {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: left;
    font-size: var(--project1r104);
    font-family: "Raleway", sans-serif;
    color: #00395f;
    padding: var(--project1r08) 0;
}

.project1datacont li img {
    width: var(--project1r3);  
    vertical-align: middle;
    margin: var(--project1r05);
}
